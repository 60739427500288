<template>
  <div>
    <b-card>
      <div class="text-center">
        <div>
          <h1>{{$utils.kstD().slice(0, 4)}} BALAAN</h1>
          <!--<h1>1등 럭셔리 플랫폼이 되기 위한 준비를 끝내자!</h1>-->
          <!--          <h1>고객의 문제가 가장 많이 해결되는 Q2</h1>-->
        </div>
        <!--<div>Q1 BALAAN</div>
        <h2>발란의 견고한 성장과 커뮤니티 무기 장착을, 위하여!</h2>-->
        <!--<div>Q2 BALAAN</div>
        <h2>오늘 나의 결과물을 고객이 기대하고 기다린다.</h2>-->
        <!--<div>Q3 BALAAN</div>
        <h2>고객과 파트너가 자발적으로 찾아오는 플랫폼을 만들자!</h2>-->
      </div>
      <!--      <div class="mb-3">
              <h4>고객의 소리</h4>
            </div>-->
      <b-collapse id="collapse">
        <b-row v-if="$R('BALAANEER')" class="mb-2">
          <b-col cols="12">
            <shop-preset v-model="form.shop"></shop-preset>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="6">
            <brand-preset v-model="form.brand" :hideDisabled="true"></brand-preset>
          </b-col>
          <b-col md="6">
            <category-preset v-model="form.category"></category-preset>
            <!--<b-form-checkbox v-model="form.exactCategory">하위카테고리를 포함하지 않습니다</b-form-checkbox>-->
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="3">
            <small>평점</small><br/>
            <b-form inline>
              <b-form-input class="text-center w-50px" v-model.number="form.point_from"></b-form-input>
              ~
              <b-form-input class="text-center w-50px" v-model.number="form.point_to"></b-form-input>
            </b-form>
          </b-col>
          <b-col md="3">
            <small>발란코드 지정</small><br/>
            <b-form-input class="w-125px" v-model="form.search"></b-form-input>
          </b-col>
          <b-col md="3">
            <small>ID 지정</small><br/>
            <b-form-input class="" v-model="form.m_id"></b-form-input>
          </b-col>
        </b-row>
      </b-collapse>
      <div class="my-2">
        <b-button class="m-1" variant="primary" @click="list" :disabled="busy.list">검색
          <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
        <b-button class="m-1" variant="success" v-b-toggle.collapse>상세조건</b-button>
        <b-button class="m-1" variant="outline-success" @click="redraw">재정렬</b-button>
      </div>
      <div class="mx-n1" v-masonry transition-duration="0s" item-selector=".item" :origin-top="true" :horizontal-order="false">
        <div v-masonry-tile
             class="px-2 item col-md-3"
             v-for="(r, idx) in items.list">
          <b-card>
            <h4 class="clearfix">
              <div class="float-right badge badge-light pointer" @click="searchMId(r.m_id)">{{ r.m_id }}</div>
              {{ r.id }}
              <b-badge variant="danger" v-if="r.del_yn === 'Y'">삭제됨</b-badge>
            </h4>
            <div v-if="r.img_cnt > 1">
              <div class="d-flex flex-row">
                <div class="d-flex flex-column mr-1">
                  <div v-for="i in r.imgs" class="mb-1">
                    <img :src="webp(i, {thumb: 400})" style="max-width:80px" @mouseover="e=>{r.img_selected = i; redraw()}" @click="$utils.open(webp(i))"
                         @load="redraw"/>
                  </div>
                </div>
                <div class="flex-grow-1 flex-shrink-1">
                  <a v-if="r.img_selected" :href="webp(r.img_selected)" target="_blank">
                    <img class="w-100" :src="webp(r.img_selected, {thumb: 400})" @load="redraw"/>
                  </a>
                </div>
              </div>
            </div>
            <div v-else-if="r.img_cnt === 1">
              <a v-if="r.img_selected" :href="webp(r.img_selected)" target="_blank">
                <img class="w-100" :src="webp(r.img_selected, {thumb: 400})" @load="redraw"/>
              </a>
            </div>
            <div class="clearfix">
              <div class="float-right mt-1 badge badge-light">주문시각: {{ $moment(r.orddt).format('YYYY-MM-DD HH:mm') }}</div>
              <a :href="`/#/deliveryBoard/${r.ordno}/${r.sno}`" target="_blank" class="badge badge-secondary">{{ r.ordno }}:{{ r.sno }}</a>
              <br/>
              <div class="float-right badge badge-light">리뷰작성: {{ $moment(r.regdt).format('YYYY-MM-DD HH:mm') }}</div>
              <span><i v-for="_ in Array(r.point)" class="fa fa-star"></i><i v-for="_ in Array(5-r.point)" class="fa fa-star-o"></i></span>&nbsp;&nbsp;
            </div>
            <div>
              <a :href="`/#/shop/${r.shop_id}`" target="_blank">
                <b-badge variant="success">SHOP {{ r.shop_id }}</b-badge>
              </a>
              <a :href="`/#/goods/${r.goodsno}`" class="ml-1" target="_blank">
                <b-badge variant="primary">{{ r.goodsno }}</b-badge>
              </a>
              <b-badge class="ml-1" variant="secondary">{{ r.opt1 }}</b-badge>
              <b-badge variant="light">{{ $utils.comma(r.price) }} 원</b-badge>
              <br/>
              <small>{{ r.goodsnm }}</small>
            </div>
            <hr/>
            <div class="mt-2">
              {{ r.contents }}
            </div>
          </b-card>
        </div>
      </div>
      <div class="text-center">
        <b-button variant="primary" size="lg" @click="list(true)" v-if="hasMore" :disabled="busy.listmore">더보기
          <b-spinner variant="info" small class="ml-1" v-if="busy.listmore"></b-spinner>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'Dashboard',
  title: '대시보드',
  data() {
    return {
      form: {
        search: '',
        m_id: '',
        point_from: 1,
        point_to: 5,
        shop: [],
        brand: [],
        category: [],
        exactCategory: true,
        limit: 30,
        skip: 0,
      },
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      scrollEventListener: null,
    }
  },
  async created() {
    this.list();
  },
  mounted() {
    this.scrollEventListener = () => {
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
      if (bottomOfWindow) {
        this.list(true);
      }
    };
    window.addEventListener('scroll', this.scrollEventListener);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollEventListener);
  },
  methods: {
    async list(more) {
      const form = this.form;
      const shop = form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const body = {...form, shop, brand, category};

      let j = await this.$api.postTable(this, '/reviews', body, {more, fnAssign: this.assignTableData});
      this.redraw();
    },
    assignTableData(e) {
      e.img_cnt = e.img ? e.img.split('|').length : 0;
      if (e.img_cnt) {
        e.imgs = e.img.split('|').map(i => i.startsWith('RV') ? `https://www.balaan.co.kr/shop/data/review_tmp/${i}` : `https://i.balaan.io/review/${i}`);
        e.img_selected = e.imgs[0];
      }
    },
    searchMId(m_id) {
      this.form.m_id = m_id;
      this.list();
    },
    redraw() {
      setTimeout(e => this.$redrawVueMasonry(), 50);
    },
    onScroll(tg) {
      let {target: {scrollTop, clientHeight, scrollHeight}} = tg;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.list(true);
      }
    },
    webp(src, {thumb} = {}) {
      if (!src.includes('i.balaan.io')) return src;
      const img_base = src.replace(/\.\w+$/, '');
      const webp = thumb ? img_base + `_${thumb}.webp` : img_base + '.webp';
      return webp;
    }
  }
}
</script>
